<style lang="scss" scoped>
.bill {
	background-color: #f2f3f5;
}
.nav_menu {
	float: right;
	font-size: 0.3rem;
	margin-left: -2rem;
}

.btns {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.sum_info {
	font-size: 0.24rem;
	margin-top: 0.2rem;
	padding: 0.3rem;
	background-color: #fff;
	.time {
		margin-bottom: 0.1rem;
	}
	.value {
		font-size: 0.3rem;
		font-weight: bold;
	}
	.r {
		margin-right: 0.4rem;
	}
}
.label {
	color: $font_color_info;
}

.goods_name {
	font-size: 0.22rem;
	color: $font_color_sec;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.all_assured_btn {
	color: $color_main;
}
.list_header {
	// border-top: 1px solid $font_color_sec;
}
.bill_item .seq {
	color: #969799;
}
.goods_info {
	display: flex;
	align-items: center;
	.seq {
		width: 0.6rem;
	}
	.assureds {
		width: calc(100% - 0.6rem - 1.2rem - 1.2rem);
		padding: 0 0.1rem;
		box-sizing: border-box;
	}
	.money {
		width: 1.2rem;
		padding: 0.04rem;
		box-sizing: border-box;
		text-align: center;
	}
	.state {
		width: 1.2rem;
		text-align: right;
	}
	.assureds_header {
		text-align: center;
	}
	.state_success {
		// color: $success;
	}
	.state_error {
		color: $danger;
	}
}
.empty {
	background-color: #fff;
}

.text_center {
	text-align: center;
}

.detail_search_content {
	padding: 0.3rem 0;
	background-color: #f2f3f5;
	.btn {
		padding: 0.3rem 0.3rem 0;
	}
}

.assured_row {
	display: flex;
	align-items: center;
	.name {
		width: 30vw;
		text-align: left;
	}
}

::v-deep .van-dialog__content {
	max-height: 70vh;
	overflow: auto;
}

.btn_short {
	background-color: $color_back_main;
	border-color: #70b3f8;
}
</style>

<template>
	<div class="bill">
		<top-nav :showBack="false"
			>保险订单查询
			<span class="nav_menu" @click="logout">退出登录</span>
		</top-nav>

		<!-- 查询条件 -->
		<van-button type="default" block @click="deatailSearchPop = true">
			<van-icon class="iconfont ybx_more" color="#1989fa" />
		</van-button>

		<!-- 快捷查询按钮 -->
		<div class="btns">
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(30)">最近半小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(60)">最近1小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(120)">最近2小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(0)">今天</van-button>
		</div>

		<!-- 统计信息 -->
		<div class="sum_info">
			<div class="label time" v-text="`${startTime ? startTime : '~'} 至 ${endTime ? endTime : '~'}`"></div>
			<span class="label">已支付订单数量：</span>
			<span class="value r" v-text="hasPayBillNum"></span>
			<span class="label">已支付被保险人数量：</span>
			<span class="value" v-text="hasPayAssuredNum"></span>
		</div>

		<van-cell border>
			<div class="list_header goods_info">
				<span class="seq seq_header">序号</span>
				<span class="assureds assureds_header">被保人</span>
				<span class="money money_header">金额</span>
				<span class="state state_header">支付状态</span>
			</div>
		</van-cell>
		<van-empty v-show="!list.length" class="empty" description="暂无订单" />
		<van-list v-model="loading" :finished="finished" :finished-text="list.length ? '没有更多了' : ''" @load="onLoad">
			<van-cell v-for="(item, index) in list" :key="index">
				<div class="bill_item">
					<div class="goods_name">
						<span v-text="`${item.proName} - ${item.comboName}`"></span>
						<span v-text="item.createTime"></span>
					</div>
					<div class="goods_info">
						<span class="seq" v-text="index + 1"></span>
						<span class="assureds">
							<span class="assured_name" v-text="assuredNameFormatter(item)"></span>
							<span class="all_assured_btn" @click="showAllAssured(item)" v-text="` (${item.assureNum > showNameNum ? '等' : ''}${item.assureNum}人)`"></span>
						</span>
						<span class="money" v-text="item.premium"></span>
						<span class="state" :class="{ state_success: item.payState == 1, state_error: item.payState == 0 }" v-text="payStateDict[String(item.payState)]"></span>
					</div>
				</div>
			</van-cell>
		</van-list>

		<!-- 被保险人弹窗 -->
		<van-dialog v-model="assuredDialog" className="assured_dialog" title="被保险人" confirmButtonText="关闭" confirmButtonColor="#2594EF">
			<van-cell v-for="(as, index) in assuredList" :key="index" value-class="text_center">
				<div class="assured_row">
					<span class="name" v-text="as.name"></span>
					<span class="mobile" v-text="as.mobile"></span>
				</div>
			</van-cell>
		</van-dialog>

		<!-- 筛选条件 -->
		<van-popup v-model="deatailSearchPop" position="top">
			<div class="detail_search_content">
				<van-field v-model="startTime" readonly label="开始时间" @click="checkStartTime" placeholder="请选择开始时间">
					<template #button>
						<van-button size="small" type="primary" @click.stop="startTime = ''">清空</van-button>
					</template>
				</van-field>
				<van-field v-model="endTime" readonly label="结束时间" @click="checkEndTime" placeholder="请选择结束时间">
					<template #button>
						<van-button size="small" type="primary" @click.stop="endTime = ''">清空</van-button>
					</template>
				</van-field>
				<van-field v-model="assureInfo" label="查询条件" placeholder="姓名/证件号码/手机号码" @click="openPop()"></van-field>
				<van-field v-model="payState" label="支付状态" is-link readonly placeholder="请选择支付状态" @click="openPop('payStatePop')"></van-field>
				<van-field v-model="user" label="会员" is-link readonly placeholder="请选择子账户或部门账户" @click="openPop('userPop')"></van-field>

				<div class="btn">
					<van-button type="info" block @click="detailSearch">查询</van-button>
				</div>
			</div>
		</van-popup>

		<!-- 时间选择 -->
		<van-popup v-model="timePop" round position="bottom" :duration="duration">
			<van-datetime-picker v-model="time" type="datetime" :title="timePickerTitle" @cancel="timePop = false" @confirm="timeConfirm" />
		</van-popup>

		<!-- 支付状态选择 -->
		<van-popup v-model="payStatePop" round position="bottom" :duration="duration">
			<van-picker title="支付状态" show-toolbar :columns="payStateList" value-key="name" @confirm="payStateConfirm" @cancel="payStatePop = false" />
		</van-popup>

		<!-- 下级账户选择 -->
		<van-popup v-model="userPop" round position="bottom" :duration="duration">
			<van-picker title="会员" show-toolbar :columns="childAccountList" visible-item-count="5" value-key="userName" @confirm="userConfirm" @cancel="userPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, Button, Loading, List, Empty, Dialog, Icon, Popup, Field, DatetimePicker, Picker, Toast } from 'vant';
import { http_getBills, http_getSubAccount } from '@/request/billSearch';
import { http_logout } from '@/request/common';
import { payState, payStateDict } from '@/config/fixedParams';

export default {
	name: 'bill', // 查单系统订单页面
	components: {
		[Button.name]: Button,
		[Loading.name]: Loading,
		[List.name]: List,
		[Cell.name]: Cell,
		[Empty.name]: Empty,
		[Icon.name]: Icon,
		[Popup.name]: Popup,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker,
		[Dialog.Component.name]: Dialog.Component,
	},
	data() {
		return {
			startTime: '2021-08-01 00:00:00',
			endTime: '2021-09-10 20:20:16',
			assureInfo: '', // 查询条件
			payState: '', // 支付状态
			user: '', // 子账号或部门账号名称

			payStateChecked: null,
			userChecked: null,

			hasPayBillNum: 0,
			hasPayAssuredNum: 0,
			list: [],

			loading: false,
			finished: false,
			total: 0,
			currentPage: -1,
			showNameNum: 6, // 要显示的被保险人名字的数量

			payStateDict: payStateDict,

			assuredDialog: false,
			assuredList: [],

			deatailSearchPop: false,
			timePop: false,
			time: '',
			timePickerTitle: '选择开始时间',

			payStatePop: false,

			userPop: false,
			childAccountList: [],

			duration: 0.3, // pop动画时长

			isShortSearch: true, // 是否为快捷查询
		};
	},
	computed: {
		payStateList() {
			return [{ name: '请选择支付状态', value: '' }, ...payState];
		},
	},
	created() {
		this.startTime = `${this.$base.getDate()} 00:00:00`;
		this.endTime = this.$base.getDateTime();

		this.getSubAccount();
	},
	methods: {
		// 打开pop
		openPop(notKey) {
			if (!notKey) {
				this.duration = 0;
			} else {
				this.duration = 0.3;
			}

			let keys = ['timePop', 'payStatePop', 'userPop'];
			keys.forEach(key => {
				this[key] = notKey === key;
			});
		},
		// 选择下级账户
		userConfirm(v) {
			this.userChecked = v;
			this.user = v.userName;
			this.userPop = false;
		},

		// 选择支付状态
		payStateConfirm(v) {
			this.payStateChecked = v;
			this.payState = v.name;
			this.payStatePop = false;
		},

		// 选择开始时间
		checkStartTime() {
			this.time = this.startTime ? new Date(this.startTime.replace(/-/g, '/')) : new Date();
			this.timePickerTitle = '选择开始时间';
			this.openPop('timePop');
		},

		// 选择结束时间
		checkEndTime() {
			this.time = this.endTime ? new Date(this.endTime.replace(/-/g, '/')) : new Date();
			this.timePickerTitle = '选择结束时间';
			this.openPop('timePop');
		},

		// 选择时间
		timeConfirm(value) {
			if (this.timePickerTitle.includes('开始')) {
				this.startTime = this.$base.getDateTime(value);
			} else {
				this.endTime = this.$base.getDateTime(value);
			}
			this.timePop = false;
		},

		logout() {
			http_logout().then(() => {
				this.$router.push('login');
			});
		},

		detailSearch() {
			this.openPop();
			this.deatailSearchPop = false;
			this.isShortSearch = false;
			this.initList();
			this.search();
		},

		// 快捷查询
		shortSearch(minutes) {
			if (minutes) {
				this.startTime = this.$base.getDateTime(Date.now() - minutes * 60 * 1000);
			} else {
				this.startTime = `${this.$base.getDate()} 00:00:00`;
			}
			this.endTime = this.$base.getDateTime();
			this.isShortSearch = true;

			this.initList();
			this.search();
		},

		// 初始化数据
		initList() {
			this.list = [];
			this.currentPage = -1;
			this.finished = false;
		},

		onLoad() {
			if ((!this.list.length && !this.finished) || this.list.length < this.total) {
				this.search();
			} else {
				this.finished = true;
			}
		},

		// 查询数据
		search() {
			this.currentPage++;
			let send = {
				limit: 10,
				page: this.currentPage,
				startTime: this.startTime,
				endTime: this.endTime,
				assureInfo: '',
				payState: '',
				userId: '',
			};
			if (!this.isShortSearch) {
				send.assureInfo = this.assureInfo;
				send.payState = this.payStateChecked ? this.payStateChecked.value : '';
				send.userId = this.userChecked ? this.userChecked.userId : '';
			}
			this.loading = true;
			http_getBills(send)
				.then(res => {
					this.total = res.total;
					this.hasPayBillNum = res.billSum.billNum;
					this.hasPayAssuredNum = res.billSum.assureNum;
					let list = res.rows.map(item => {
						item.assuredList = String(item.assureInfo)
							.split(',')
							.map(as => {
								let asList = as.split('&');
								return { name: asList[0], mobile: asList.length > 1 ? asList[1] : '' };
							});
						return item;
					});
					this.list.push(...list);

					if (res.total == 0) {
						this.finished = true;
					}
				})
				.catch(err => {
					this.finished = true;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// 查询子账号
		getSubAccount() {
			http_getSubAccount()
				.then(res => {
					this.childAccountList = [{ userId: '', userName: '请选择子账户或部门账户' }, ...res.rows];
				})
				.catch(err => {
					if (err.code === 9) {
						this.$router.push('login');
					}
				});
		},

		// 被保险人名称格式化
		assuredNameFormatter(info) {
			if (info.assureNum <= this.showNameNum) {
				return info.assuredList.map(item => item.name).join(',');
			} else {
				return info.assuredList
					.slice(0, this.showNameNum)
					.map(item => item.name)
					.join(',');
			}
		},

		// 显示所有被保险人
		showAllAssured(info) {
			this.assuredList = info.assuredList;
			this.assuredDialog = true;
		},
	},
};
</script>
