// 订单查询系统接口
import { get, post, postParams } from './http';

// 密码或验证码登录
export function login(data) {
	return post('/insurance/api/user/passwordLoginFromH5', data);
}

// 查询订单
export function http_getBills(data) {
	return post('/insurance/api/order/getUserBillList', data);
}

// 查询部门账号及子账号
export function http_getSubAccount() {
	return get('/insurance/api/order/getSubAccount');
}

// 余额支付
export function http_payByBalance(data) {
	return postParams('/insurance/api/pay/accountPay', data);
}

// 获取个人账户信息
export function http_getAccountInfo() {
	return get('/insurance/api/fn/accountInfo');
}

// 查询订单(包含被保人列表)
export function http_getBillsContainUserInfo(data) {
	return post('/insurance/api/order/getUserBillContainsInsuredPersonList', data);
}

// 批量修改失效订单的保险期限
export function http_batchUpdateInsurancePeriod(data) {
	return post('/insurance/api/order/batchUpdateInsurancePeriod', data);
}

// 余额充值
export function http_recharge(data) {
	return post('insurance/api/miniApp/recharge', data);
}
